import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
//components
import Login from "./containers/Login";
import Navbar from "./components/Navbar";
import RecordForm from "./containers/RecordForm";
import ViewDetails from "./containers/ViewDetails";
import ViewDetailsDosa from "./containers/ViewDetails/indexDoSA";
import AppRoutes from "./components/AppRoutes";
import ViewIndividualDetails from "./containers/ViewIndividualDetails";
import ViewIndividualDetailsDosa from "./containers/ViewIndividualDetails/indexDoSA";
//misc
import { UI_ROUTES, DOSA_LOGIN } from "./config/constants";
import _isEmpty from "lodash/isEmpty";
import { history } from "./config/history";
import { checkAuth, logout } from "./store/actions/AuthActions";
import GenerateReport from "./containers/GenerateReport";
import SearchRecord from "./containers/SearchRecord";
import RecordFormDoSA from "./containers/RecordFormDoSA";
class App extends Component {
  componentDidMount() {
    this.props.checkAuth();
  }
  getRoutes = (hasWriteAccess, loginId) => [
    {
      path: UI_ROUTES.INDIVIDUAL_REPORT_DOSA,
      component: ViewIndividualDetailsDosa,
      exact: false,
    },
    {
      path: UI_ROUTES.INDIVIDUAL_REPORT,
      component: ViewIndividualDetails,
      exact: false,
    },

    ...(hasWriteAccess && loginId !== DOSA_LOGIN
      ? [
          {
            path: UI_ROUTES.ENTER_RECORD,
            component: RecordForm,
            exact: false,
          },
        ]
      : []),
    ...(loginId === DOSA_LOGIN
      ? [
          {
            path: UI_ROUTES.ENTER_RECORD,
            component: RecordFormDoSA,
            exact: false,
          },
        ]
      : []),
    {
      path: UI_ROUTES.VIEW_RECORDS,
      component: ViewDetails,
      exact: false,
    },
    {
      path: UI_ROUTES.VIEW_RECORDS_DOSA,
      component: ViewDetailsDosa,
      exact: false,
    },
    {
      path: UI_ROUTES.GENERATE_REPORT,
      component: GenerateReport,
      exact: false,
    },
    {
      path: UI_ROUTES.SEARCH_RECORD,
      component: SearchRecord,
      exact: false,
    },
  ];
  render() {
    const {
      auth: {
        data: { permissions = [], loginId } = {},
        isAuthenticated,
        loading,
      },
      logout,
    } = this.props;
    const hasWriteAccess = permissions.indexOf("WRITE") > -1;

    return (
      <Router history={history}>
        <Navbar
          hasWriteAccess={hasWriteAccess}
          isAuthenticated={isAuthenticated}
          loginId={loginId}
          logout={logout}
        >
          {!isAuthenticated ? (
            <Login />
          ) : (
            <AppRoutes routes={this.getRoutes(hasWriteAccess, loginId)} />
          )}
        </Navbar>
      </Router>
    );
  }
}
const mapStateToProps = (globalState) => {
  const { auth } = globalState;
  return {
    auth,
  };
};

const mapDispatchToProps = () => {
  return {
    checkAuth: checkAuth,
    logout,
  };
};
export default connect(mapStateToProps, mapDispatchToProps())(App);
