import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { fetchRecords } from "../../store/actions/RecordActions";
class ViewDetails extends Component {
  componentDidMount() {
    this.props.fetchRecords({ onlyInstitute: true });
  }
  render() {
    const { data: { records = [] } = {} } = this.props;
    return (
      <Grid container>
        <Grid xs={2} className="report-container" item />
        <Grid xs={8} className="report-container" item>
          <Table component={Card}>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <b>Roll No.</b>
                </TableCell>
                <TableCell align="center">
                  <b>Name</b>
                </TableCell>
                <TableCell align="center">
                  <b>Entries</b>
                </TableCell>
                <TableCell align="center">
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map(({ rollno, name, entries }, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{rollno}</TableCell>
                  <TableCell align="center">{name}</TableCell>
                  <TableCell align="center">{entries}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      to={`/ui/student/${rollno}/dosa`}
                      component={Link}
                      color="primary"
                    >
                      View More
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid xs={2} className="report-container" item />
      </Grid>
    );
  }
}

const mapStateToProps = (globalState) => {
  const {
    records: { loading, loaded, data },
  } = globalState;
  return { loading, loaded, data };
};

const mapDispacthToProps = () => {
  return {
    fetchRecords,
  };
};
export default connect(mapStateToProps, mapDispacthToProps())(ViewDetails);
