import React, { Component } from "react";
import { connect } from "react-redux";
import LoginComponent from "../../components/Login";
import { login } from "../../store/actions/AuthActions";
import _get from "lodash/get";
import Loader from "../../components/Loader";
class Login extends Component {
  state = {
    username: "",
    password: "",
  };

  updateState = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    this.setState({ [id]: value });
  };

  login = () => {
    this.props.login({
      ...this.state,
    });
  };

  render() {
    const { loading } = this.props;
    return loading ? (
      <Loader />
    ) : (
      <LoginComponent
        {...this.props}
        {...this.state}
        login={this.login}
        updateState={this.updateState}
      />
    );
  }
}
const mapStateToProps = (globalState) => {
  const { auth: { loading, loaded, data } = {} } = globalState;
  return {
    loading,
    loaded,
    data,
  };
};
const mapDispatchToProps = () => {
  return { login };
};
export default connect(mapStateToProps, mapDispatchToProps())(Login);
