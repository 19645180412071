import { GET_RECORDS, SAVE_RECORD } from "./actionTypes";
import { getActionTypes } from "./utils";
const initialState = {
  loading: false,
  loaded: false,
  error: false,
  data: {},
};

export default (state = initialState, action) => {
  const {
    SUCCESS: saveRecordSuccess,
    REQUEST: saveRecordReq,
    FAIL: saveRecordFail,
  } = getActionTypes(SAVE_RECORD);
  const {
    SUCCESS: getRecordSuccess,
    REQUEST: getRecordReq,
    FAIL: getRecordFail,
  } = getActionTypes(GET_RECORDS);
  switch (action.type) {
    case getRecordReq:
      return {
        ...state,
        ...action.payload,
      };
    case getRecordSuccess:
      return {
        ...state,
        ...action.payload,
      };
    case getRecordFail:
      return {
        ...state,
        ...action.payload,
      };
    case saveRecordReq:
      return {
        ...state,
        ...action.payload,
      };
    case saveRecordSuccess:
      return {
        ...state,
        ...action.payload,
      };
    case saveRecordFail:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
