import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { UI_ROUTES, DOSA_LOGIN } from "../../config/constants";
import { Button } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: ({ isAuthenticated }) => {
        return !isAuthenticated ? `100%` : `calc(100% - ${drawerWidth}px)`;
      },
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const routes = [
  { name: "Enter Details", route: UI_ROUTES.ENTER_RECORD },
  { name: "Check Details - Hostels", route: UI_ROUTES.VIEW_RECORDS },
  { name: "Check Details - DoSA", route: UI_ROUTES.VIEW_RECORDS_DOSA },
  { name: "Search Record", route: UI_ROUTES.SEARCH_RECORD },
  { name: "Generate Report", route: UI_ROUTES.GENERATE_REPORT },
];

function ResponsiveDrawer(props) {
  const {
    window,
    children,
    isAuthenticated = false,
    logout,
    hasWriteAccess = false,
    loginId,
  } = props;
  const classes = useStyles({ isAuthenticated });
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let newRoutes = [...routes];
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  if (loginId !== DOSA_LOGIN) {
    newRoutes.splice(2, 1);
  }
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {newRoutes.map(({ name, route }, index) => {
          if (!hasWriteAccess && route === UI_ROUTES.ENTER_RECORD) return;
          return (
            <ListItem button key={index} to={route} component={Link}>
              <ListItemText primary={name} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography className="title-text" variant="h6" noWrap>
            Hostel Defaulters Portal
          </Typography>
          {isAuthenticated && (
            <Button
              onClick={logout}
              className="logout-button"
              variant="outlined"
              color="default"
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
