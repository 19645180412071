import { getActionTypes } from "../utils";
import { SAVE_RECORD, GET_RECORDS } from "../actionTypes";
import Axios from "axios";
import { API_URLS } from "../../config/constants";
import { history } from "../../config/history";
import { UI_ROUTES, USER_TYPES } from "../../config/constants";
import { convertJsonToFormData } from "../utils";

export const saveRecord = (data, actionType) => (dispatch) => {
  const { SUCCESS, REQUEST, FAIL } = getActionTypes(SAVE_RECORD);
  dispatch({
    type: REQUEST,
    payload: {
      loading: true,
      actionType,
    },
  });
  const formData = convertJsonToFormData(data);
  Axios.post(API_URLS.SAVE_RECORD, formData, {
    headers: {
      accept: "application/json",
      contentType: "multipart/form-data",
    },
  })
    .then(({ data }) => {
      dispatch({
        type: SUCCESS,
        payload: {
          loading: false,
          loaded: true,
          actionType,
          hasError: false,
        },
      });
      alert("Saved Successfully");
    })
    .catch(({ error }) => {
      alert("Invalid Input");
      dispatch({
        type: FAIL,
        payload: {
          loading: false,
          loaded: true,
          isAuthenticated: false,
          error: error,
          hasError: true,
          actionType,
        },
      });
      // history.push(window.location.pathname);
    });
};

export const fetchRecords = (data, actionType) => (dispatch) => {
  const { SUCCESS, REQUEST, FAIL } = getActionTypes(GET_RECORDS);
  dispatch({
    type: REQUEST,
    payload: {
      loading: true,
      actionType,
    },
  });
  Axios.get(API_URLS.GET_RECORDS, { params: { ...data } })
    .then(({ data }) => {
      dispatch({
        type: SUCCESS,
        payload: {
          loading: false,
          loaded: true,
          actionType,
          data: {
            records: data,
          },
        },
      });
    })
    .catch(({ error }) => {
      dispatch({
        type: FAIL,
        payload: {
          loading: false,
          loaded: true,
          isAuthenticated: false,
          error: error,
          actionType,
        },
      });
    });
};
