import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Grid, TextField } from "@material-ui/core";
import Loader from "../../components/Loader";
import { API_URLS } from "../../config/constants";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Axios from "axios";

export default class SearchRecord extends Component {
  state = { isLoading: false, error: false, data: [], rollno: "" };

  getStudentDetails = () => {
    const { rollno } = this.state;
    this.setState({ isLoading: true });
    Axios.get(`${API_URLS.GET_STUDENT_RECORD}/${rollno}?onlyInstitute=true`)
      .then(({ data }) => {
        // console.log(data);
        !data.length && alert("No Records found");
        this.setState({ data, isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  onChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    this.setState({ [id]: value });
  };

  render() {
    const { data, isLoading, rollno } = this.state;

    const downloadPath =
      window.location.hostname === "localhost" ? "http://localhost:3001" : "";
    return (
      <Grid container className="generate-report">
        <Grid xs={3} item></Grid>

        <Grid xs={6} className="center" item>
          <TextField
            id="rollno"
            variant="outlined"
            style={{ width: "300px" }}
            onChange={this.onChange}
            value={rollno}
            label="Roll Number*"
            InputLabelProps={{ shrink: true }}
          />
          <div className="clearfix" />
          <br />
          <Button
            disabled={this.state.rollno.length < 9}
            variant="outlined"
            onClick={this.getStudentDetails}
          >
            Search
          </Button>
        </Grid>
        <Grid xs={3} item></Grid>

        {isLoading ? (
          <Loader />
        ) : (
          <Grid container className="searchTable">
            <Grid xs={2} className="report-container" item />
            <Grid xs={8} className="report-container" item>
              {!!data.length && (
                <Table component={Card}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <b>Roll No.</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Name</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Branch</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Hostel</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Room No.</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Date Reported</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Remarks</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Action taken</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Attachment</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map(
                      (
                        {
                          rollno,
                          name,
                          branch,
                          action,
                          incidentHostel,
                          incidentRoomNo,
                          remarks,
                          attachments,
                          dateReported,
                        },
                        index
                      ) => (
                        <TableRow key={index}>
                          <TableCell align="center">{rollno}</TableCell>
                          <TableCell align="center">{name}</TableCell>
                          <TableCell align="center">{branch}</TableCell>
                          <TableCell className="capital" align="center">
                            {incidentHostel}
                          </TableCell>
                          <TableCell className="capital" align="center">
                            {incidentRoomNo ? incidentRoomNo : "-"}
                          </TableCell>
                          <TableCell>
                            {new Date(dateReported).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </TableCell>
                          <TableCell align="center">{remarks}</TableCell>
                          <TableCell align="center">
                            {action ? action : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {attachments ? (
                              <Button
                                href={`${downloadPath}/api/download/${attachments}`}
                                download={attachments}
                                target="_blank"
                                color="primary"
                                variant="outlined"
                                size="small"
                              >
                                Download
                              </Button>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              )}
            </Grid>
            <Grid xs={2} className="report-container" item />
          </Grid>
        )}
      </Grid>
    );
  }
}
