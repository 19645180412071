import { getActionTypes } from "../utils";
import { AUTH_CHECK, LOGOUT, LOGIN } from "../actionTypes";
import Axios from "axios";
import { API_URLS } from "../../config/constants";
import { history } from "../../config/history";
import { UI_ROUTES, USER_TYPES } from "../../config/constants";

export const checkAuth = (data) => (dispatch) => {
  const { SUCCESS, REQUEST, FAIL } = getActionTypes(AUTH_CHECK);
  dispatch({
    type: REQUEST,
    payload: {
      loading: true,
    },
  });

  Axios.get(API_URLS.AUTH_CHECK)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS,
        payload: {
          loading: false,
          loaded: true,
          isAuthenticated: true,
          data: { ...data },
        },
      });
      history.push(UI_ROUTES.ENTER_RECORD);
    })
    .catch(({ error }) => {
      dispatch({
        type: FAIL,
        payload: {
          loading: false,
          loaded: true,
          isAuthenticated: false,
          error: error,
        },
      });
      // history.push(window.location.pathname);
    });
};

export const login = (data, userType = null) => (dispatch) => {
  const { SUCCESS, REQUEST, FAIL } = getActionTypes(LOGIN);
  dispatch({
    type: REQUEST,
    payload: {
      loading: true,
    },
  });

  Axios.post(API_URLS.LOGIN, data)
    .then(({ data: userData }) => {
      dispatch({
        type: SUCCESS,
        payload: {
          loading: false,
          loaded: true,
          isAuthenticated: true,
          data: userData,
        },
      });
      history.push(UI_ROUTES.ENTER_RECORD);
    })
    .catch(({ error }) => {
      alert("Invalid Credentials");
      dispatch({
        type: FAIL,
        payload: {
          loading: false,
          loaded: true,
          error: error,
        },
      });
      // history.push("/");
    });
};

export const logout = (data) => (dispatch) => {
  dispatch({
    type: LOGOUT,
    payload: {
      isAuthenticated: false,
    },
  });
  Axios.post(API_URLS.LOGOUT);
};
