import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Loader from "../../components/Loader";
import { API_URLS } from "../../config/constants";
import Axios from "axios";
import CSVDownload from "react-csv-downloader";

const columns = [
  {
    id: "rollno",
    displayName: "Roll Number",
  },
  {
    id: "name",
    displayName: "Name",
  },
  {
    id: "branch",
    displayName: "Branch",
  },
  {
    id: "incidentHostel",
    displayName: "Incident Hostel",
  },
  {
    id: "incidentRoomNo",
    displayName: "Incident Room No.",
  },
  {
    id: "hostel",
    displayName: "Current Hostel",
  },
  {
    id: "roomNo",
    displayName: "Current Room No.",
  },
  // {
  //   id: "seaterType",
  //   displayName: "Room Type",
  // },
  {
    id: "dateReported",
    displayName: "Date",
  },

  {
    id: "remarks",
    displayName: "Remarks",
  },
  {
    id: "action",
    displayName: "Action Taken",
  },
  {
    id: "attachments",
    displayName: "Attachment",
  },
];

const parseData = (data = []) => {
  // console.log(data);
  const hostName = window.location.origin;
  return data.map((record) => ({
    rollno: record.rollno,
    name: record.name,
    branch: record.branch,
    incidentHostel: record.incidentHostel,
    incidentRoomNo: record.incidentRoomNo,
    hostel: record.studentdetails ? record.studentdetails.hostel : "-",
    roomNo: record.studentdetails ? record.studentdetails.roomNo : "-",
    dateReported: record.dateReported,
    //seaterType: record.studentdetails ? record.studentdetails.seaterType : "-",
    attachments: record.attachments
      ? `${hostName}/api/download/${record.attachments}`
      : "",
    remarks: record.remarks,
    action: record.action,
  }));
};

export default class GenerateReport extends Component {
  downoadButton = React.createRef();
  state = { isLoading: false, error: false, data: [] };

  getFullReport = () => {
    if (this.state.data.legth > 0) {
      return;
    }
    Axios.get(API_URLS.FULL_REPORT).then(({ data }) => {
      const parsedData = parseData(data);
      this.setState({ data: parsedData }, this.downloadCSV);
    });
  };

  downloadCSV = () => {
    this.downoadButton.current.handleClick();
  };

  render() {
    return (
      <Grid container className="generate-report">
        <Grid xs={12}>
          {this.state.isLoading ? (
            <Loader message="Fetching data..." />
          ) : (
            <Button
              onClick={this.getFullReport}
              color="primary"
              variant="contained"
            >
              Download full report
            </Button>
          )}
          <div className="csv-download">
            <CSVDownload
              datas={this.state.data}
              columns={columns}
              ref={this.downoadButton}
              filename="Defaulters"
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}
