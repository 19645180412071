import React, { Component } from "react";
import Axios from "axios";
import { API_URLS } from "../../config/constants";

import Grid from "@material-ui/core/Grid";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Loader from "../../components/Loader";
export default class ViewIndividualDetails extends Component {
  state = { isLoading: false, data: [], error: false };

  componentDidMount() {
    this.getStudentDetails();
  }
  getStudentDetails = () => {
    const {
      match: {
        params: { rollno },
      },
    } = this.props;

    Axios.get(`${API_URLS.GET_STUDENT_RECORD}/${rollno}`, {
      params: { onlyInstitute: true },
    })
      .then(({ data }) => {
        // console.log(data);
        this.setState({ data });
      })
      .catch((err) => {});
  };

  render() {
    const { data = [], isLoading, error } = this.state;

    const downloadPath =
      window.location.hostname === "localhost" ? "http://localhost:3001" : "";
    return isLoading ? (
      <Loader />
    ) : (
      <Grid container>
        <Grid xs={2} className="report-container" item />
        <Grid xs={8} className="report-container" item>
          <Table component={Card}>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <b>Roll No.</b>
                </TableCell>
                <TableCell align="center">
                  <b>Name</b>
                </TableCell>
                <TableCell align="center">
                  <b>Branch</b>
                </TableCell>
                <TableCell align="center">
                  <b>Date Reported</b>
                </TableCell>
                <TableCell align="center">
                  <b>Remarks</b>
                </TableCell>
                <TableCell align="center">
                  <b>Action Taken</b>
                </TableCell>
                <TableCell align="center">
                  <b>Attachment</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(
                (
                  {
                    rollno,
                    name,
                    branch,
                    action,
                    dateReported,
                    remarks,
                    attachments,
                  },
                  index
                ) => (
                  <TableRow key={index}>
                    <TableCell align="center">{rollno}</TableCell>
                    <TableCell align="center">{name}</TableCell>
                    <TableCell className="capital" align="center">
                      {branch}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(dateReported).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </TableCell>
                    <TableCell align="center">{remarks}</TableCell>
                    <TableCell align="center">
                      {action ? action : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {attachments ? (
                        <Button
                          href={`${downloadPath}/api/download/${attachments}`}
                          download={attachments}
                          target="_blank"
                          color="primary"
                          variant="outlined"
                          size="small"
                        >
                          Download
                        </Button>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid xs={2} className="report-container" item />
      </Grid>
    );
  }
}
