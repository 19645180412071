export const UI_ROUTES = {
  ENTER_RECORD: "/ui/enter",
  VIEW_RECORDS: "/ui/view",
  VIEW_RECORDS_DOSA: "/ui/view-dosa",
  LOGIN: "/ui/login",
  GENERATE_REPORT: "/ui/generate",
  INDIVIDUAL_REPORT: "/ui/student/:rollno",
  INDIVIDUAL_REPORT_DOSA: "/ui/student/:rollno/dosa",
  SEARCH_RECORD: "/ui/search",
};

export const USER_TYPES = {
  ADMIN: "admin",
  NON_ADMIN: "non-admin",
};

export const API_URLS = {
  LOGIN: "/api/login",
  GET_RECORDS: "/api/getRecord",
  AUTH_CHECK: "/api/checkAuth",
  SAVE_RECORD: "/api/createrecord",
  GET_STUDENT_RECORD: "/api/student",
  FULL_REPORT: "/api/report/full",
  LOGOUT: "/api/logout",
};

export const DEFAULT_FORM_STATE = {
  rollno: "",
  name: "",
  remarks: "",
  attachment: null,
  dateReported: new Date().toISOString().substr(0, 10),
  block: "",
  room: "",
  branch: "",
  action: "",
};

export const DOSA_LOGIN = "dosa@thapar.edu";
