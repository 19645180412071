import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";

class AppRoutes extends Component {
  static propTypes = {
    routes: PropTypes.array,
    location: PropTypes.object,
    showNotFound: PropTypes.bool,
    extraParams: PropTypes.any,
  };

  render() {
    const { showNotFound, extraParams } = this.props;
    return (
      <Switch>
        {this.props.routes.map((route, index) => {
          const { component: Component, path, params, isExact } = route;

          return (
            <Route
              isExact={!!isExact}
              key={index}
              path={path}
              render={(props) => {
                return (
                  <Component {...extraParams} {...props} params={params} />
                );
              }}
            />
          );
        })}
        {showNotFound && <Route component={<h1>404</h1>} />}
      </Switch>
    );
  }
}
export default withRouter(AppRoutes);
