import { getActionTypes } from "./utils";
import { AUTH_CHECK, LOGIN, LOGOUT } from "./actionTypes";
const initialState = {
  loading: false,
  loaded: false,
  isAuthenticated: false,
  error: null,
  data: {},
};

export default (state = initialState, action) => {
  const {
    REQUEST: authCheckRequest,
    SUCCESS: authCheckSuccess,
    FAIL: authCheckFail,
  } = getActionTypes(AUTH_CHECK);
  const {
    REQUEST: loginRequest,
    SUCCESS: loginSuccess,
    FAIL: loginFail,
  } = getActionTypes(LOGIN);

  switch (action.type) {
    case authCheckRequest:
      return {
        ...state,
        ...action.payload,
      };
    case authCheckSuccess:
      return {
        ...state,
        ...action.payload,
      };
    case authCheckFail:
      return {
        ...state,
        ...action.payload,
      };
    case loginRequest:
      return {
        ...state,
        ...action.payload,
      };
    case loginSuccess:
      return {
        ...state,
        ...action.payload,
      };
    case loginFail:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
