import React, { Component } from "react";
import { connect, Provider } from "react-redux";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import _get from "lodash/get";
import { saveRecord } from "../../store/actions/RecordActions";
import { DEFAULT_FORM_STATE } from "../../config/constants";
import { FormControl, InputLabel, Select } from "@material-ui/core";
class RecordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...DEFAULT_FORM_STATE,
    };
    this.fileRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    this.resetState(prevProps);
  }

  onChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    this.setState({ [id]: value });
  };

  saveRecord = () => {
    const {
      rollno,
      name,
      remarks,
      attachment,
      dateReported,
      block,
      room,
      branch,
      action,
    } = this.state;
    this.props.saveRecord(
      {
        rollno,
        name,
        remarks,
        attachment,
        dateReported,
        room: `${block}-${room}`,
        branch,
        action,
      },
      "saveRecord"
    );
  };

  resetState = (prevProps) => {
    if (
      !this.props.hasError &&
      prevProps.loading !== this.props.loading &&
      this.props.actionType === "saveRecord"
    ) {
      this.fileRef.current.value = null;
      this.setState({
        ...DEFAULT_FORM_STATE,
      });
    }
  };

  setFile = (e) => {
    const file = _get(this.fileRef.current, "files[0]", null);

    if (file && file.size > 5120000) {
      alert("File size should be less than 5 MB");
      return (this.fileRef.current.value = null);
    }

    this.setState({
      attachment: file,
    });
  };

  isFormValid = () => {
    const { rollno, name, remarks, block, room, branch, action } = this.state;
    return (
      rollno.length >= 9 &&
      name.length >= 3 &&
      remarks.length >= 5 &&
      block.length >= 1 &&
      room.length >= 1 &&
      branch.length >= 3 &&
      action.length >= 5
    );
  };

  render() {
    const {
      rollno,
      name,
      remarks,
      dateReported,
      block,
      room,
      branch,
      action,
    } = this.state;
    return (
      <Grid container>
        <Grid xs={3} item />
        <Grid xs={6}>
          <h1 className="form-header">Enter Details</h1>
          <div className="form-container">
            <TextField
              onChange={this.onChange}
              id="rollno"
              label="Roll Number *"
              variant="outlined"
              value={rollno}
            />
            <div className="clearfix" />
            <TextField
              onChange={this.onChange}
              id="name"
              label="Name *"
              variant="outlined"
              value={name}
            />
            <div className="clearfix" />
            <TextField
              type="date"
              id="dateReported"
              label="Date"
              variant="outlined"
              value={dateReported}
              onChange={this.onChange}
            />
            <div className="clearfix" />
            <TextField
              onChange={this.onChange}
              id="branch"
              label="Branch *"
              variant="outlined"
              style={{ width: "330px" }}
              value={branch}
            />
            <div className="clearfix" />
            <TextField
              onChange={this.onChange}
              id="remarks"
              label="Remarks *"
              variant="outlined"
              style={{ width: "330px" }}
              multiline
              value={remarks}
            />
            <div className="clearfix" />
            <div className="roomNo">
              <TextField
                onChange={this.onChange}
                id="block"
                label="Block *"
                variant="outlined"
                value={block}
                style={{
                  width: "80px",
                  marginRight: "20px",
                }}
              />
              <TextField
                onChange={this.onChange}
                id="room"
                label="Room No *"
                variant="outlined"
                value={room}
                style={{
                  width: "230px",
                }}
              />
            </div>
            <div className="clearfix" />
            <TextField
              type="file"
              id="attachment"
              label="Attachment"
              variant="outlined"
              inputRef={this.fileRef}
              inputProps={{
                accept: ".png,.jpg,.jpeg,.pdf",
              }}
              InputLabelProps={{ shrink: true }}
              onChange={this.setFile}
            />
            <div className="clearfix" />
            <TextField
              onChange={this.onChange}
              id="action"
              label="Action taken *"
              variant="outlined"
              style={{ width: "330px" }}
              multiline
              value={action}
            />
            {/* <FormControl variant="outlined">
              <InputLabel htmlFor="action">Action Taken</InputLabel>
              <Select
                style={{ width: "330px", textAlign: "left" }}
                value={action}
                onChange={(props) => {
                  this.onChange({
                    ...props,
                    target: { ...props.target, id: "action" },
                  });
                }}
                inputProps={{
                  name: "action",
                }}
              >
                <option value={"Pending"}>None</option>
                <option value={"Disciplinary Probation"}>
                  Disciplinary Probation
                </option>
                <option value={"Expelled for 1 year"}>
                  Expelled for 1 year
                </option>
                <option value={"Expelled for 2 years"}>
                  Expelled for 2 years
                </option>
                <option value={"Expelled for 3 years"}>
                  Expelled for 3 years
                </option>
              </Select>
              </FormControl> */}
            <div className="clearfix" />
            <Button
              variant="contained"
              onClick={this.saveRecord}
              className="submit-form"
              color="primary"
              disabled={this.props.loading || !this.isFormValid()}
            >
              {this.props.loading ? "Saving...." : "Submit"}
            </Button>
          </div>
        </Grid>
        <Grid xs={3} />
      </Grid>
    );
  }
}
const mapStateToProps = (globalState) => {
  const {
    records: { loading, loaded, data, hasError, actionType },
  } = globalState;
  return { loading, loaded, data, actionType, hasError };
};
const mapDispatchToProps = () => {
  return {
    saveRecord: saveRecord,
  };
};
export default connect(mapStateToProps, mapDispatchToProps())(RecordForm);
