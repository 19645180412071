export const getActionTypes = (prefix, hasReset, nameSpace) => {
  let actions;
  if (nameSpace) {
    actions = {
      REQUEST: `${nameSpace}${prefix}_REQUEST`,
      SUCCESS: `${nameSpace}${prefix}_SUCCESS`,
      FAIL: `${nameSpace}${prefix}_FAIL`,
      ...(hasReset ? { RESET: `${nameSpace}${prefix}_RESET` } : {}),
    };
    return actions;
  }
  actions = {
    REQUEST: `${prefix}_REQUEST`,
    SUCCESS: `${prefix}_SUCCESS`,
    FAIL: `${prefix}_FAIL`,
    ...(hasReset ? { RESET: `${prefix}_RESET` } : {}),
  };
  return actions;
};

export const convertJsonToFormData = (obj) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};
