import React, { Fragment } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
export default function Loader({ message }) {
  return (
    <div className="loader">
      <CircularProgress size={100} />
      <span className="loader-msg">{message}</span>
    </div>
  );
}
